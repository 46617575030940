import React from 'react'

const DateTime = ({data, weekDay = false}) => {
  const dayNames = ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La']
  const date = new Date(data)

  return (
    <span>{weekDay ? `${dayNames[date.getDay()]} ` : null}{date.toLocaleDateString("fi-FI")} klo {date.getHours().toString().padStart(2, '0')}:{date.getMinutes().toString().padStart(2, '0')}</span>
  )
}

export default DateTime