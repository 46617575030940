import React from "react"
import * as R from "ramda"
import { graphql, Link } from "gatsby"
import LayoutController from "../components/layoutController"

//import Backgroundimage from 'gatsby-background-image'
import ReactMarkdown from "react-markdown"
//import Img from "gatsby-image"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
//import Button from '@material-ui/core/Button'

//import NavigateBefore from '@material-ui/icons/NavigateBefore'
//import NavigateNext from '@material-ui/icons/NavigateNext'
import DateTime from "../helpers/DateTime"
import Seo from "../components/seo"
import Prose from "../components/prose"
//import SocialShare from "../components/socialShare"

import * as styles from "./internalReleasePage.module.scss"

const InternalReleasePage = ({ data, pageContext, location }) => {
  const STTData = R.path(
    ["allInternalReleases", "nodes", 0, "releases"],
    data
  ).find(release => {
    return release.alternative_id === pageContext.sttID
  })

  const published = STTData ? R.path(["published"], STTData) : ""
  const title = STTData ? R.path(["title"], STTData) : ""
  const lead = STTData ? R.path(["leadtext"], STTData) : ""
  const content = STTData ? R.path(["body"], STTData) : ""
  const mainImage = STTData ? R.path(["mainImage", "url"], STTData) : ""

  const seoTitle = title
  const seoDesc = lead

  return (
    <LayoutController language="fi"> 
    <Box>
      <Seo
        seoTitle={seoTitle}
        seoDesc={seoDesc}
        url={typeof window !== "undefined" ? window.location.href : ""}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "35px",
          padding: "0 20px",
        }}
      >
        <Box style={{ maxWidth: "1200px", width: "100%" }}>
          <Box>
            <Grid container>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
              <Grid item xs={10} className={styles.center}>
                <Grid container>
                  <Grid item xs={12} lg={8}>
                    <div className={styles.date}>
                      <DateTime data={published} />
                    </div>
                    <h1 style={{ paddingRight: "20px" }}>{title}</h1>
                    <p>
                      <em>{lead}</em>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
              <Grid item xs={10} className={styles.center}>
                <div style={{ width: "80%" }}>
                  {typeof mainImage === "string" && mainImage && (
                    <picture>
                      <img
                        src={mainImage}
                        style={{ width: "100%", maxHeight: "656px" }}
                        alt=""
                      />
                    </picture>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
              <Grid item xs={10} className={styles.center}>
                <Grid container>
                  <Grid item xs={12} lg={8}>
                    <div style={{ marginBottom: "83px" }}>
                      <Prose content={content} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
    </LayoutController>
  )
}

export default InternalReleasePage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query internalReleasePageQuery {
    allInternalReleases(filter: { id: { ne: "dummy" } }) {
      nodes {
        releases {
          alternative_id
          mainImage {
            url
            normal
            thumbnail_square
          }
          published
          title
          leadtext
          leadTextOrBodyStrip
          body
          mainImage {
            url
          }
        }
      }
    }
  }
`
